import { above, media } from 'utils/mediaqueries';
import { borderRadius, hoverRadius } from 'config/theme/borderRadius';
import { headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import BackgroundImage from 'components/background/BackgroundImage';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'components/text/Tag';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import { backgroundFlexibleProp } from 'utils/proptypes/modules/mediaProps';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Div = styled('div')``;

const StyledBackgroundImage = styled(BackgroundImage)`
    overflow: hidden;
    width: 100%;
    transition: border-radius ${transitions.primary};

    ${above.tabletSm} {
        width: calc(50% - 8px);

        ${media.hover} {
            :hover {
                border-radius: ${hoverRadius};
            }
        }
    }
`;

/**
 *  ContentBlock used to display image, heading, text, tag and buttons.
 *
 * @param {object} background - Background image as object.
 * @param {array} buttons - Buttons as array.
 * @param {string} heading - Heading as a string.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {string} tag - Small tag displayed below heading.
 * @param {array} text - Text as a wysiwyg-array.
 */

const Information = ({
    background = {},
    buttons = [],
    contentAlignment = 'left',
    heading = '',
    headingTag = '',
    tag = '',
    text = '',
}) => (
    <Div display="flex" flexDirection={['column', null, 'row']} gap={['none', null, '24px']} height="100%">
        <StyledBackgroundImage
            aspectRatio={[ratios.vertical, null, null, null, 'none']}
            borderRadius={borderRadius}
            order={['1', null, contentAlignment === 'left' ? '2' : '1']}
            position={background.backgroundPosition}
            query={mediaQueries.twoColumn}
            size={background.backgroundSize}
            src={background.url}
        />
        <Div
            marginTop={['24px', null, '0']}
            order={['2', null, contentAlignment === 'left' ? '1' : '2']}
            width={['100%', null, 'calc(50% - 8px)']}
        >
            <Div display="flex" flexDirection="column" maxWidth="800px">
                {tag && <Tag marginBottom={['12px', null, '8px']}>{tag}</Tag>}
                {heading && (
                    <Heading
                        as={headingTag}
                        fontKeys={['Recife Display/40', null, 'Recife Display/56', null, 'Recife Display/56']}
                        marginBottom={['12px', null, '24px']}
                    >
                        {heading}
                    </Heading>
                )}
                {text && (
                    <Div marginBottom="24px">
                        <Wysiwyg
                            data={text}
                            tagComponents={{ url: TextLinkWysisyg }}
                            textComponent={props => <WysiwygParagraph {...props} />}
                        />
                    </Div>
                )}
                {buttons.length > 0 && (
                    <ButtonsList buttons={buttons} flexWrap="wrap" marginBottom={['0', null, '24px']} />
                )}
            </Div>
        </Div>
    </Div>
);

Information.propTypes = {
    background: backgroundFlexibleProp,
    buttons: buttonsProp,
    contentAlignment: PropTypes.oneOf(['left', 'right']),
    heading: PropTypes.string.isRequired,
    headingTag: headingTagProp,
    tag: PropTypes.string,
    tagSize: PropTypes.string,
    text: wysiwygProp,
};

export default Information;

import InformationBlock from 'libs/wordpress/content/cmsBlocks/InformationBlock';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import moduleSpacing from 'config/theme/moduleSpacing';
import useClientHeight from 'hooks/useClientHeight';

const InformationModule = ({ ...rest }) => {
    const clientHeight = useClientHeight();

    return (
        <MaxWidthWrapper
            includeContentSpacing
            as="section"
            height={['auto', null, null, null, clientHeight]}
            py={moduleSpacing.md}
        >
            <InformationBlock {...rest} />
        </MaxWidthWrapper>
    );
};

export default InformationModule;

InformationModule.propTypes = {
    settings: PropTypes.object,
};

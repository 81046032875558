import { headingObjectProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import Information from 'components/contentBlocks/Information';
import PropTypes from 'prop-types';
import React from 'react';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import transformButtons from 'libs/wordpress/utils/transformButtons';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const InformationBlock = ({ content }) => {
    const {
        background_media_flexible: backgroundFlexible = {},
        buttons = [],
        content_alignment: contentAlignment = '',
        heading = {},
        tag = '',
        text = '',
    } = content;

    const isAboveTabletSm = useAboveBreakpoint('tabletSm');
    const transformedBackground = transformFlexibleBackground(backgroundFlexible);
    const mobileBackground = transformedBackground.mobile;
    const desktopBackground = transformedBackground.desktop;

    return (
        <Information
            background={isAboveTabletSm ? desktopBackground : mobileBackground}
            buttons={buttons ? transformButtons(buttons) : undefined}
            contentAlignment={contentAlignment}
            heading={decodeHTMLEntities(heading?.text)}
            headingTag={heading?.type}
            tag={decodeHTMLEntities(tag)}
            text={text}
        />
    );
};

InformationBlock.propTypes = {
    content: PropTypes.shape({
        'background_media_flexible': PropTypes.exact({
            mobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
            desktop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
        }),
        buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
        'content_alignment': PropTypes.oneOf(['left', 'right']),
        heading: headingObjectProp,
        tag: PropTypes.string,
        text: wysiwygProp,
    }).isRequired,
};

export default InformationBlock;
